import React from 'react';
import useSiteMetadata from '../../../hooks/use-site-metadata';
import favicon from '../../../images/favicon.png';

function SEO({
  title, description, pathname, children, image, ogTitle, ogDescription,
}: {
  title: string,
  description: string,
  pathname: string,
  image: string,
  ogTitle: string,
  ogDescription: string,
  children?: React.ReactNode,
}) {
  const {
    title: defaultTitle, description: defaultDescription, siteUrl, image: defaultImage,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ''}`,
    image: image || defaultImage,
    ogTitle: ogTitle || defaultTitle,
    ogDescription: ogDescription || defaultDescription,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="title" content={seo.title} />
      <meta name="og:title" content={seo.ogTitle} />
      <meta name="description" content={seo.description} />
      <meta name="og:description" content={seo.ogDescription} />
      <meta name="og:image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      <link rel="icon" type="image/x-icon" href={favicon} />
      {children}
    </>
  );
}

export default SEO;
